import React from 'react'
import Sebrae from '../assets/imgs/sebrae.png'

const BcxSectionD = () => {
  return (
    <section id="sectionD">
      <div className="container">
        <div className="header">
          <div className="titulo">
            <img src={Sebrae} alt="Logo da Sebrae" />
            <h2>
              Uma Parceria com <br />
              <strong>soluções para sua empresa</strong>
            </h2>
          </div>
          <div className="descricao">
            <p>
              O Sebrae está oferecendo 3 consultorias presenciais aos
              empreendedores interessados.
            </p>
            <p>
              Mesmo sem efetivação do crédito, você pode ter acesso a
              ferramentas que te ajudam a gerenciar melhor seus negócios.
            </p>
            <p>
              <strong>Aproveite essa oportunidade!</strong>
            </p>
          </div>
        </div>

        <div className="content">
          <div className="col1">
            <h4>01 Consultoria de Controles Financeiros Básicos</h4>
            <p>
              Entenda como implantar controles financeiros básicos, preparando a
              empresa para uma gestão financeira estruturada.
            </p>
            <p>
              <span>Para quem:</span>
              <br />
              Empreendedores e empresários que desejam implementar controles
              básicos para gestão financeira. <br />
              Indicado para microempreendedores individuais – MEI’s.
            </p>
            <p>
              <span>O que a empresa recebe:</span>
              <br />
              Planilha de controles financeiros básicos.
            </p>
            <p>
              Carga horária: 5h (2 encontros presenciais + 1 hora de preparação
              do material pelo consultor)
            </p>
            <p>
              <span>Curso Online: </span>
              <a href="https://digital.sebraers.com.br/produtos/financas/curso/curso-online-credivoti/" style={{display: 'inline-block', padding: '3px 7.5px', backgroundColor: '#2853A2', color: '#fff', minWidth: '120px', textAlign: 'center', borderRadius: '15.5px'}}>Acesse aqui</a>
            </p>
          </div>
          <div className="col2">
            <h4>02 Consultoria Fluxo de Caixa e Capital de Giro</h4>
            <p>
              Analisar as informações necessárias para a gestão do fluxo de
              caixa, com visitas a assegurar os recursos e instrumentos
              financeiros para a manutenção e viabilização do negócio.
              Construir, analisar e/ou otimizar o fluxo de caixa e estruturar as
              operações financeiras.
            </p>
            <p>
              <span>Para quem:</span>
              <br />
              Empreendedores que desejam implementar o controle de fluxo de
              caixa. <br />
              Indicado para micro e pequenas empresas – ME e EPP.
            </p>
            <p>
              <span>O que a empresa recebe:</span>
              <br />
              Fluxo de caixa otimizado e necessidade de capital de giro.
            </p>
            <p>
              Carga horária: 8h (3 encontros presenciais + 2 horas de preparação
              do material pelo consultor)
            </p>
          </div>
          <div className="col3">
            <h4>
              03 Consultoria de Indicadores de Desempenho Econômico Financeiro
            </h4>
            <p>
              Construir e/ou analisar os principais indicadores
              econômico-financeiros, oportunizando uma visão geral da empresa e
              a melhoria da qualidade das decisões tomadas.
            </p>
            <p>
              <span>Para quem:</span>
              <br />
              Empreendedores que queiram conhecer ou analisar os indicadores e
              resultados financeiros e econômicos do seus negócios. <br />
              Indicado para micro e pequenas empresas – ME e EPP
            </p>
            <p>
              <span>O que a empresa recebe:</span>
              <br />
              Conjunto de indicadores de desempenho econômico-financeiros,
              contendo lucratividade, rentabilidade, margem de contribuição e
              ponto de equilíbrio.
            </p>
            <p>
              Carga horária: 8h (3 encontros presenciais + 2 horas de preparação
              do material pelo consultor)
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BcxSectionD
