import React from 'react'
import logoCredIvoti from '../assets/imgs/logo-w.png'
import logosB from '../assets/imgs/logos.png'

const BcxSectionA = () => {
  return (
    <section id="SectionA">
      <div className="container">
        <div className="text">
          <p>
            O CredIvoti é uma solução para auxiliar prestadores de serviços e
            micro e pequenos empresários a transformarem os seus negócios.
          </p>
          <p>
            O objetivo é facilitar o acesso ao crédito, possibilitando
            investimentos em infraestrutura, planejamento de ampliação,
            melhorias de gestão e outras necessidades da empresa.
          </p>
          <p>
            Essa é uma união de esforços entre a Prefeitura de Ivoti, Sicredi
            Pioneira e RSGaranti para fortalecer a economia local!
          </p>
        </div>

        <div className="logos">
          <img
            className="credLogo"
            src={logoCredIvoti}
            alt="Logotipo da CredIvoti"
          />
          <img
            className="parceiros"
            src={logosB}
            alt="Logotipos Sicredi, Sebrae, RS Garanti, Prefeitura de Ivoti"
          />
        </div>
      </div>
    </section>
  )
}

export default BcxSectionA
