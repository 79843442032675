import React from 'react'
import Pessoa1 from '../assets/imgs/pessoa-1.png'
import Pessoa2 from '../assets/imgs/pessoa-2.png'
import Pessoa3 from '../assets/imgs/pessoa-3.png'
import Aviso from '../assets/imgs/ico-8.png'

const BcxSectionE = () => {
  return (
    <section id="sectionE">
      <div className="container">
        <h2 className="title">
          Quem pode contar
          <br />
          <strong> com o CredIvoti</strong>
        </h2>

        <div className="micInd rowCard">
          <div className="imagem">
            <img src={Pessoa1} alt="Mulher sorrindo com braços cruzados" />
          </div>

          <div className="texto">
            <h3>
              Microempreendedor
              <br />
              <strong>Individual</strong>
            </h3>
            <p>
              com receita bruta anual igual <br /> ou inferior a R$ 81 mil
            </p>
            {/* <p>
              <strong>Limite de crédito:</strong>
              <br />
              R$ 10 mil em 36 parcelas (sem carência) de até R$ 370,00
              <br />
              R$ 10 mil em 33 parcelas (03 meses de carência) de até R$ 410,00
              <br />
              *A taxa da garantia RSGaranti é de até
              <br />
              R$ 662,40
            </p> */}
            <p></p>
          </div>
        </div>

        <div className="micEmp rowCard">
          <div className="imagem">
            <img src={Pessoa2} alt="Homem sorrindo" />
          </div>

          <div className="texto">
            <h3>
              <strong>Microempresa</strong>
            </h3>
            <p>
              com receita bruta anual igual <br /> ou inferior a R$ 360 mil
            </p>
            {/* <p>
              <strong>Limite de crédito:</strong>
              <br />
              <br />
              R$ 55 mil em 36 parcelas (sem carência) de até R$ 2.020,00
              <br />
              R$ 55 mil em 33 parcelas (03 meses de carência) de até R$ 2.230,00
              <br />
              *A taxa da garantia RSGaranti é de até <br />
              R$ 3.643,20
            </p> */}
          </div>
        </div>

        <div className="peqEmp rowCard">
          <div className="imagem">
            <img
              src={Pessoa3}
              alt="Homem segurando tablet e demonstrando algo para uma mulher que está sorrindo"
            />
          </div>

          <div className="texto">
            <h3>
              Pequenas
              <br />
              <strong>Empresas</strong>
            </h3>
            <p>
              Com receita bruta anual igual <br /> ou inferior a R$ 4,8 milhões
            </p>
            {/* <p>
              <strong>Limite de crédito:</strong>
              <br />
              <br />
              R$ 100 mil em 36 parcelas (sem carência) de até R$ 3.670,00
              <br />
              R$ 100 mil em 33 parcelas (03 meses de carência) de até R$
              4.050,00
              <br />
              * A taxa da garantia RSGaranti é de até
              <br />
              R$ 6.624,00
            </p> */}
          </div>
        </div>

        <div className="aviso">
          <div className="imgAviso">
            <img
              src={Aviso}
              alt="Icone, formato Balão, com Exclamação dentro"
            />
          </div>

          <div className="textoAviso">
            <p>
              • Crédito sujeito a análise de crédito. Parcelas simuladas sem
              IOF.
              <br />• Terão acesso a linha associados que apresentarem no mínimo
              01 ano de faturamento completo, declarados nos documentos
              indicados na documentação necessária/abaixo.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BcxSectionE
