import React from 'react'
import logoCredIvoti from '../assets/imgs/logo.png'

const AcxHeader = () => {
  return (
    <section id="header">
      <div className="container">
        <div className="slogan">
          <img src={logoCredIvoti} alt="Logotipo da CredIvoti" />
          <h1>
            Solução em crédito
            <br />
            <span>para a sua empresa</span>
          </h1>
        </div>
      </div>
    </section>
  )
}

export default AcxHeader
